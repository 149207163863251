import router from '@/router';

[ {
    path: '/outlets',
    name: "outlet-home",
    component: () => import( /* webpackChunkName: "outlet" */ './views/OutletHome'),
    meta: {
        reqAuth: true
    },
},
{
    path: '/outlets/:id',
    name: "outlet-detail",
    component: () => import( /* webpackChunkName: "orders" */ './views/OutletDetail'),
    meta: {
        reqAuth: true,
        permission: 'outlet_read'
    },
},
].forEach(router.addRoute);