<div id="menu-main" class="menu menu-box-right rounded-0 bg-white">
  <div class="card rounded-0 mb-2 primary-light-bg" data-card-height="152">
    <div class="card-top ">
      <a href="#" id="closeMenuIcon" class="close-menu float-start me-2 text-center mt-2 icon-40 notch-clear">
        <i class="fa fa-times color-white font-15"></i></a>
    </div>
    <div class="card-center text-center">
      <img src="/images/lifely/logo-white.png" class="mb-4" width="50">
    </div>
    <div class="card-bottom bg-gradient">
      <div v-if="!user" class="ps-4">
        <p class="mb-1 font-14 color-white opacity-70">Welcome</p>
        <p class="mb-2 color-white mt-n1 font-800 font-16" style="letter-spacing: 0.7px;">Lifely Partner</p>
      </div>
      <div v-if="user" class="ps-4">
        <p class="mb-1 font-14 color-white opacity-70">Welcome</p>
        <p class="mb-2 color-white mt-n1 font-800 font-16">{{ user ? user.full_name : ""}}</p>
      </div>
    </div>
  </div>

  <div class="list-group list-custom-small list-menu " v-if="!user">
    <router-link :to="{name: 'home'}" class="close-menu btn-ripple">
      <i class="fa fa-home gradient-bg-green color-white iconBoxShadow"></i>
      <span>Partner Program</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
  </div>

  <div class="list-group list-custom-small list-menu" v-if="!user">
    <h6 class="menu-divider ps-1 mt-4">Account</h6>

    <router-link :to="{name: 'request'}" class="close-menu btn-ripple">
      <i class="fas fa-user-plus gradient-bg-green color-white iconBoxShadow"></i>
      <span>Register</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
    <router-link :to="{name: 'login'}" class="close-menu btn-ripple">
      <i class="fas fa-sign-in-alt gradient-bg-green color-white iconBoxShadow"></i>
      <span>Login</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
  </div>
  <div class="list-group list-custom-small list-menu" v-if="user">
    <router-link :to="{name: 'dashboard-home'}" class="close-menu btn-ripple"
      v-if="$store.state.user.user_type == 'super_admin'">
      <i class="fas fa-tachometer-alt gradient-bg-green color-white iconBoxShadow"></i>
      <span>Dashboard</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
    <router-link :to="{name: 'profile'}" class="close-menu btn-ripple">
      <i class="fas fa-user gradient-bg-green color-white iconBoxShadow"></i>
      <span>Profile</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
    <a href="#" class="btn-ripple" data-menu="referranceLinkMenu" v-if="user && user.referral_link">
      <i class="fas fa-share-alt gradient-bg-green color-white iconBoxShadow"></i>
      <span>Referral Link</span>
      <i class="fa fa-angle-right"></i>
    </a>
    <router-link :to="{name: 'order-home'}" class="close-menu btn-ripple"
      v-if="hasPermission('order_read') && $store.state.user.user_type == 'super_admin'">
      <i class="fas fa-shopping-cart gradient-bg-green color-white iconBoxShadow"></i>
      <span>Orders</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
    <router-link :to="{name: 'notification-home'}" class="close-menu btn-ripple">
      <i class="fas fa-bell gradient-bg-green color-white iconBoxShadow"></i>
      <span>Notifications</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
  </div>

  <div v-if="user">
    <h6 class="menu-divider mt-4"
      v-if="hasPermission('cluster_admin_read') || hasPermission('partner_read') || hasPermission('partner_admin_read') || hasPermission('customer_read')">
      People
    </h6>
    <div class="list-group list-custom-small list-menu">
      <router-link :to="{name: 'partners-home'}" class="close-menu btn-ripple"
        v-if="hasPermission('partner_read') && $store.state.user.user_type == 'super_admin'">
        <i class="fas fa-hands-helping gradient-bg-green color-white iconBoxShadow"></i>
        <span>Partners</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
      <router-link :to="{name: 'customer-home'}" class="close-menu btn-ripple" v-if="hasPermission('customer_read')">
        <i class="fas fa-users gradient-bg-green color-white iconBoxShadow"></i>
        <span>Customers</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
      <router-link :to="{name: 'cluster-admin-home'}" class="close-menu btn-ripple"
        v-if="hasPermission('cluster_admin_read')">
        <i class="fas fa-user-shield gradient-bg-green color-white iconBoxShadow"></i>
        <span>Cluster Admin</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
      <router-link :to="{name: 'partner-admin-home'}" class="close-menu btn-ripple"
        v-if="hasPermission('partner_admin_read')">
        <i class="fas fa-users-cog gradient-bg-green color-white iconBoxShadow"></i>
        <span>Partner Admin</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
    </div>
  </div>

  <div v-if="user && hasPermission('outlet_read')">
    <h6 class="menu-divider mt-4">Franchies</h6>
    <div class="list-group list-custom-small list-menu">
      <router-link :to="{name: 'outlet-home'}" class="close-menu btn-ripple">
        <i class="fas fa-store-alt gradient-bg-green color-white iconBoxShadow"></i>
        <span>Outlets</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
    </div>
  </div>

  <div v-if="user">
    <h6 class="menu-divider mt-4" v-if="hasPermission('commission_read')">Finance</h6>
    <div class="list-group list-custom-small list-menu">
      <router-link :to="{name: 'commission-home'}" class="close-menu btn-ripple"
        v-if="hasPermission('commission_read')">
        <i class="fas fa-percentage gradient-bg-green color-white iconBoxShadow"></i>
        <span>Commissions</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
      <router-link :to="{name: 'payments'}" class="close-menu btn-ripple"
        v-if="hasPermission('payment_transaction_read')">
        <i class="fas fa-credit-card gradient-bg-green color-white iconBoxShadow"></i>
        <span>Payments</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
      <router-link :to="{name: 'passbook-users'}" class="close-menu btn-ripple"
        v-if="hasPermission('passbook_statement_read') && 'user_type' in $store.state.user && (hasSuperAdmin || $store.state.user.user_type == 'partner_admin')">
        <i class="fas fa-book-open gradient-bg-green color-white iconBoxShadow"></i>
        <span>Passbook</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
      <router-link :to="{name: 'passbook-statements', params: {'userId' : $store.state.user.id}}"
        class="close-menu btn-ripple"
        v-if="hasPermission('passbook_statement_read') && $store.state.user.user_type == 'partner' ">
        <i class="fas fa-book-open gradient-bg-green color-white iconBoxShadow"></i>
        <span>Passbook</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
    </div>
  </div>

  <div v-if="user && hasPermission('referral_analytics_read')">
    <h6 class="menu-divider mt-4">Analytics</h6>
    <div class="list-group list-custom-small list-menu">
      <router-link :to="{name: 'referral_analytics-home'}" class="close-menu btn-ripple">
        <i class="fas fa-chart-line gradient-bg-green color-white iconBoxShadow"></i>
        <span>Referral Analytics</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
      <router-link :to="{name: 'audit'}" class="close-menu btn-ripple" v-if="hasSuperAdmin">
        <i class="fas fa-chart-bar gradient-bg-green color-white iconBoxShadow"></i>
        <span>Audit Logs</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
    </div>
  </div>



  <!-- <div v-if="user">
    <h6 class="menu-divider mt-4">Report</h6>
    <div class="list-group list-custom-small list-menu">
      <router-link :to="{name: 'report-config'}" class="close-menu btn-ripple">
        <i class="fas fa-cog gradient-orange color-white"></i>
        <span>Report Config</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
      <router-link :to="{name: 'report-home'}" class="close-menu btn-ripple">
        <i class="fas fa-file-alt gradient-orange color-white"></i>
        <span>Reports</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
    </div>
  </div> -->

  <div v-if="hasSuperAdmin">
    <h6 class="menu-divider mt-4">Settings</h6>
    <div class="list-group list-custom-small list-menu">
      <router-link :to="{name: 'partner-types-home'}" class="close-menu btn-ripple"
        v-if="hasPermission('partner_type_read')">
        <i class="fas fa-list gradient-bg-green color-white iconBoxShadow"></i>
        <span>Partner Types</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
      <router-link :to="{name: 'role-home'}" class="close-menu btn-ripple" v-if="hasPermission('role_read')">
        <i class="fas fa-user-tag gradient-bg-green color-white iconBoxShadow"></i>
        <span>Roles & Permission</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
      <router-link :to="{name: 'custom-attributes-home'}" class="close-menu btn-ripple"
        v-if="hasPermission('custom_attribute_read') && hasSuperAdmin">
        <i class="fas fa-wrench gradient-bg-green color-white iconBoxShadow"></i>
        <span>Custom Fields</span>
        <i class="fa fa-angle-right"></i>
      </router-link>
    </div>
  </div>

  <h6 class="menu-divider mt-4">Support</h6>
  <div class="list-group list-custom-small list-menu">
    <router-link :to="{name :'faq'}" class="close-menu btn-ripple">
      <i class="fas fa-info gradient-bg-green color-white"></i>
      <span>FAQ</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
    <router-link :to="{name: 'ticket-home'}" class="close-menu btn-ripple" v-if="user && hasPermission('ticket_read')">
      <i class="fas fa-comment-dots gradient-bg-green color-white iconBoxShadow"></i>
      <span>Tickets</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
    <router-link :to="{name:'partner-policy'}" class="close-menu btn-ripple">
      <i class="fas fa-file-pdf gradient-bg-green color-white iconBoxShadow"></i>
      <span>Partner Policy</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
  </div>
  <hr v-if="user">
  <div v-if="user">
    <div class="list-group list-custom-small list-menu mb-5 ">
      <a href="#" @click='logoutUser' class="close-menu btn-ripple">
        <i class="fa fa-sign-out-alt gradient-red color-white iconBoxShadow"></i>
        <span>Logout</span>
      </a>
    </div>
  </div>
</div>

<div id="referranceLinkMenu" class="menu menu-box-bottom rounded-m" data-menu-height="320" data-menu-effect="menu-over"
  v-if="user && user.referral_link">
  <input type="text" class="form-control copyLinkInput hide" :value="user.referral_link"
    style="opacity: 0;position: absolute;">
  <div class="menu-title">
    <!-- <p class="color-highlight">Tap a link to</p> -->
    <h1 class="font-17" style="letter-spacing: 1px;font-weight: 200;">Share Lifely Store Link</h1>
    <a href="#" class="close-menu"><i class="fa fa-times-circle text-secondary"></i></a>
  </div>
  <div class="divider divider-margins mt-3 mb-0"></div>
  <div class="content mt-0">
    <div class="list-group list-custom-small list-icon-0">

      <a href="#" class="external-link close-menu" @click="doCopy">
        <i class="fa fa-copy font-12 bg-success color-white shadow-l rounded-circle"></i>
        <span class="letterSpacing font-500">Copy</span>
        <i class="fa fa-angle-right pr-1"></i>
      </a>
      <a target="_blank" :href="whatsappLink" class="external-link">
        <i class="fab fa-whatsapp font-12 bg-whatsapp color-white shadow-l rounded-circle"></i>
        <span class="letterSpacing font-500">WhatsApp</span>
        <i class="fa fa-angle-right pr-1"></i>
      </a>
      <a target="_blank" :href="facebookLink" class="external-link">
        <i class="fab fa-facebook-f font-12 bg-facebook color-white shadow-l rounded-circle"></i>
        <span class="letterSpacing font-500">Facebook</span>
        <i class="fa fa-angle-right pr-1"></i>
      </a>
      <a target="_blank" :href="emailLink" class="external-link border-0">
        <i class="fa fa-envelope font-12 bg-mail color-white shadow-l rounded-circle"></i>
        <span class="letterSpacing font-500">Email</span>
        <i class="fa fa-angle-right pr-1"></i>
      </a>
      <!-- <a target="_blank" :href="twitterLink" class="external-link">
        <i class="fab fa-twitter font-12 bg-twitter color-white shadow-l rounded-s"></i>
        <span>Twitter</span>
        <i class="fa fa-angle-right pr-1"></i>
      </a>
      <a target="_blank" :href="linkedInLink" class="external-link">
        <i class="fab fa-linkedin-in font-12 bg-linkedin color-white shadow-l rounded-s"></i>
        <span>LinkedIn</span>
        <i class="fa fa-angle-right pr-1"></i>
      </a> -->
    </div>
  </div>
</div>