import api from "@/common/services/api";

export default new class {
    baseUrl = "/partner-orders";

    getOrders(data) {
        return api.instance.get(this.baseUrl, { params: data });
    }

    getRewardBalance() {
        return api.instance.get('/reward-statements/balance');
    }

    getOrder(id) {
        return api.instance.get(this.baseUrl + '/' + id);
    }

    getProducts() {
        return api.instance.get(this.baseUrl + '/products');
    }

    getFilteredOrdersStatistics() {
        return api.instance.get(this.baseUrl + '/stats');
    }

    createOrder(products, confirmationRequired = false, outlet) {
        return api.instance.post(this.baseUrl, {
            products: products,
            confirmationRequired: confirmationRequired,
            outlet: outlet
        });
    }


    makePaymentForOrder(id) {
        return api.instance.post(this.baseUrl + '/' + id + '/capture-payment');
    }

    summerizeOrder(products) {
        return api.instance.post(this.baseUrl + '/summary', {
            products: products
        });
    }
};